/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import _, { isNull, isUndefined } from 'lodash';
import { StyledModal, StyledBody, MainContent, PeerButton } from '../RecognitionList/styles';
import {
  HeaderContainer, UserInformation, FeedInformation,
  ImageWrapper, WrapImage, InputFieldContainer, RecognitionSection,
  FieldContainer, Description, CommentContent, SendButton, PostCommentsWrapper,
  AddButton, LikeCommentCount, LikeCommentButtonSection, LikeCommentButton, CommentTextPopup,
  CommentBoxWrap, MaxText, DownAngleV2, UserInitialsMain
} from './styles';
import Image from '../Image';
import SocialFeedTextarea from './SocialFeedTextarea';
import { ImageUrl } from '../../utils/constants';
import { getTimeToShow, applyHighlights, convertUnicode, checkImage, getOrientation, resetOrientation, createSafeHTML } from '../../utils/methods';
import { getUserPosts, addCommentOnPost, disableButton, likeUnlikePost, getUserName, getUserId, sendFriendRequest, unFriendUser, getPostLikeDetails, addBuddy, getSuggestionsList } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import SocialFeedsEmojiPicker from '../SocialFeedsEmojiPicker';
import { IconContainer } from '../EventDetails/styles';
import LazyImage from '../common/LazyImage/LazyImage';
import DeletePostPopUp from '../DeletePostPopup/deletePostConfirmation';
import RemoveFriendPopup from './RemoveBuddyPopUp/removeBuddyPoupUpdated';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SliderContainer } from "../ChallengeDashboardV2/styles";
import Slider from "react-slick-16";
import CommentsV3 from '../CommentsV3';
import { getInspirationPost } from '../../redux/actions/socialActions';
import {
  closeIcon
} from "../../utils/icons";
import moment from 'moment';
import {convertMonthFromDate, convertDateInTimezone} from '../../utils/methods';

class SingleFeed extends Component {
  constructor(props) {
    super();
    this.state = {
      showTextArea: false,
      commentText: '',
      clicked: false,
      isPostLiked: props.feed && props.feed.own_like_post === 1,
      notBase64: false,
      time: [],
      mentionedUsers: [],
      users: [],
      showGivenShoutout: false,
      shoutoutText: null,
      shoutoutImage: null,
      userIdArray: [],
      taggedUser: '',
      shoutoutType: 0,
      exemplifyID: null,
      showAddBuddy: false,
      showRemoveBuddyButton: false,
      showRemoveBuddyPopUp: false,
      ShowEmojiContainer: false,
      displayEmojiPicker: false,
      likeUsers: [],
      showRecognitionModal: false,
      postId: '',
      currentButton: '',
      feedLikeStatus: null,
      showViewCommentModal: props.showViewCommentModal ? props.showViewCommentModal : false,
      hideCommentText: props.hideCommentText === false ? false : true,
      postLikeDetail: [],
      userIdArrayV2: [],
      usersV2: [],
      position: { start: 0, end: 0 },
      showCommentArea: false,
      imgSrc: [],
      messageLength: 0,
      activeCommentTextArea: 0,
      showmore: false,
      feedId: null
    }
    this.myref = React.createRef();
    this.inputRefData = React.createRef();
  }

  handleClick = (event) => {
    if (!isNull(this.myref) && !isUndefined(this.myref) && !(this.myref && !isNull(this.myref.current) && !isUndefined(this.myref.current) && this.myref.current.contains(event.target))) {
      this.setState({ ShowEmojiContainer: false });
    }
  };

  handleActivation = () => {
    this.setState({
      activeCommentTextArea:1
    })
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    const { feed } = this.props;
    if (feed && feed.own_like_post === 1) {
      this.setState({
        feedLikeStatus: true
      })
    } else {
      this.setState({
        feedLikeStatus: false
      })
    }

    this.checkBase64(this.props.profileImage);
    if (!this.props.userName) this.props.fetchUserName();
    if (!this.props.userId) this.props.fetchUserId();
    this.setState({
      time: getTimeToShow(feed, 'feeds')
    });

    this.interval = setInterval(() => this.setState({
      time: getTimeToShow(feed, 'feeds')
    }), 60 * 1000);

    if (feed.friend === 1) {
      this.setState({
        currentButton: 'Remove Buddy'
      })
    } else if (feed.friend === 0) {
      this.setState({
        currentButton: 'Add Buddy'
      })
    } else if (feed.friend === 2) {
      this.setState({
        currentButton: 'Pending'
      })
    } else {
      this.setState({
        currentButton: ''
      })
    }
  }

  componentWillUnmount() {
    setInterval(() => clearInterval(this.interval), 65 * 1000);
    document.removeEventListener("mousedown", this.handleClick);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.successLikeOrUnlike !== nextProps.successLikeOrUnlike) {
      this.setState({
        clicked: false
      });
    }
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage);
    }

    if (this.props.feed !== nextProps.feed) {
      this.setState({
        time: getTimeToShow(nextProps.feed, 'feeds')
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postLikeDetails !== prevProps.postLikeDetails) {

      let feeds = this.props.storedFeeds;
      let feed = feeds && (feeds.response && feeds.response.length > 0 ? feeds.response : feeds)
      if (feed) {
        let feedIndex = feed.findIndex((data) => data.id == this.state.postId);
        if (feedIndex !== -1) {
          feed[feedIndex].post_like_users = this.props.postLikeDetails;
          this.props.updateFeedsLike(feed);
          this.setState({ postLikeDetail: this.props.postLikeDetails, postId: '' });
        }
      }
    }
  }

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          notBase64: false
        })
      }
      else {
        this.setState({
          notBase64: true
        })
      }
    }
  }

  showAddBuddyPopUp2 = () => {
    this.setState({ showAddBuddy: false });
  }

  showAddBuddyPopUp = (ID, feedID) => {
    const { addBuddy, feed } = this.props;
    this.setState({ showAddBuddy: true, feedId: feedID });
    addBuddy(ID);
    if (feed.friend === 1) {
      this.setState({
        currentButton: 'Remove Buddy'
      })
    } else if (feed.friend === 0) {
      this.setState({
        currentButton: 'Add Buddy'
      })
    } else if (feed.friend === 2) {
      this.setState({
        currentButton: 'Pending'
      })
    } else {
      this.setState({
        currentButton: ''
      })
    }
  }

  showTextArea = () => {
    this.setState((prev) => ({
      showTextArea: !prev.showTextArea
    }));
  };

  setRemoveBuddy = () => {
    // const { unFriendUser } = this.props;
    this.setState((prev) => ({ showRemoveBuddyButton: !prev.showRemoveBuddyButton }));
    // unFriendUser(ID, true);
  }

  handleLikePopUp = () => {
    const { handleModal, handleFeed, feed } = this.props
    handleModal();
    handleFeed(feed.id);
  }

  likeUnlikePost = () => {
    const { likeOrUnlikePost, disableLikeButton, feed, updationList, /*getInspirationPost */} = this.props;
    const likeData = {
      post_id: feed.id,
      flag: feed && feed.own_like_post === 1 ? 'unlike' : 'like',
    };
    this.setState((prev) => ({
      clicked: true,
      isPostLiked: !prev.isPostLiked,
      postId: '',
      feedLikeStatus: !prev.feedLikeStatus,
      postLikeDetail: []
    }), () => this.setState({ postId: feed.id }));
    disableLikeButton();
    likeOrUnlikePost(likeData, updationList || 'response');
    // getInspirationPost(feed.id);
  };

  addComment = () => {
    const { users, userIdArray, commentText, taggedUser, mentionedUsers, exemplifyID, imgSrc } = this.state;
    const { postComment, feed, updationList, getCommentsForPost } = this.props;
    let shoutout = {
      exemplifies: mentionedUsers.length > 0 ? mentionedUsers : userIdArray
    };
    let comment = this.toCodePoints(commentText), shoutout_user_name = '';
    if (!_.isEmpty(mentionedUsers) || !_.isEmpty(userIdArray)) {
      shoutout_user_name = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
    }
    let exemplify_id = exemplifyID;
    if (shoutout.exemplifies.length >= 1 || commentText !== ""||imgSrc.length >0) {
      postComment({ post_id: feed.id, comment, shoutout, shoutout_user_name, exemplify_id, image: imgSrc.length >= 1 ? imgSrc[0] : '' }, updationList);
    }
    this.setState({
      showTextAreaFor: null,
      commentText: '',
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      taggedUser: '',
      userIdArray: [],
      users: [],
      mentionedUsers: [],
      displayEmojiPicker: false,
      shoutoutType: 0,
      userIdArrayV2: [],
      usersV2: [],
      imgSrc: [],
      messageLength: 0
    }, () => getCommentsForPost(feed.id))
  };

  onChangeValue = (e) => {
    const text = e.target.value;
  
    // Allow user to remove characters if they exceed the limit
    if (text.length <= 500) {
      let position = this.getPosition(e.target);
      this.setState({
        position: position,
        commentText: text,
      });
    } else {
      // Prevent adding more characters beyond the limit
      let position = this.getPosition(e.target);
      this.setState({
        position: position,
        commentText: text.slice(0, 500),
      });
    }
  };

  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xD800 && c1 < 0xDC00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xDC00 && c2 < 0xE000) {
          chars = chars + ("&#" + ((+0x10000 + ((c1 - 0xD800) << 10) + (c2 - 0xDC00))) + ";</span>");
          i++;
          continue;
        }
      }
      else {
        chars = chars + b1;
      }
    }
    return chars;
  }

  getPosition(el) {
    let start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end
    };
  }

  addInspirationV2 = (e) => {
    let newText = this.state.commentText.slice(0, this.state.position.start) + e.native + this.state.commentText.slice(this.state.position.end);
    this.setState({
      position: { start: this.state.position.start + e.native.length, end: this.state.position.end + e.native.length },
      commentText: newText,
    });
  };

  updateText = (text, userId, taggedUserName) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      commentText: text,
      userIdArray: userIdArr,
      users: userNameArr,
      // taggedUser: taggedUsers,
      // showGivenShoutout: bool
    });
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplifies_id) => {
    this.setState({
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplifies_id ? exemplifies_id : null,
      mentionedUsers: mentionedUsers,
      exemplifyID: exemplifies_id ? exemplifies_id : null
    });
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      usersV2: '',
      userIdArrayV2: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  updateTextV2 = (text, userId, taggedUserName, bool) => {
    const { userIdArrayV2, usersV2, taggedUser, userIdArray } = this.state;
    let userIdArr = [...userIdArrayV2];
    let userNameArrV2 = [...usersV2];
    let userIdAll = [...userIdArray]
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userIdAll.push(userId);
      userNameArrV2.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      userIdArrayV2: userIdArr,
      usersV2: userNameArrV2,
      taggedUser: taggedUsers,
      showGivenShoutout: bool,
      userIdArray: userIdAll
    });
  };


  togglePostButton = (value) => {
    const { shoutoutText, userIdArray } = this.state;
    if (userIdArray.length !== 0) {
      if (userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else
        return true;
    } else if (value && value.trim() !== '') {
      return false;
    }
    return true;
  };

  displayEmojiContainer = () => {
    this.setState((prev) => ({
      ShowEmojiContainer: !prev.ShowEmojiContainer
    }));
  }

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmojiPicker: !prev.displayEmojiPicker
    }))
  }

  removeImage = () => {
    this.setState({
      imgSrc: [],
      messageLength: 0
    });
  };

  onChangeImage = (e) => {
    let file = e.target.files[0];
    this.setState({
      messageLength: e.target.value.length >= 1 ? "1" : '0'
    });
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            imgSrc: [reader.result],
            refreshFileReader: true,
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: [baseImage],
                refreshFileReader: false
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById(`${this.props.feed.id}-"upload"`).value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById(`${this.props.feed.id}-"upload"`).value = '';
    }
  };

  updatePosition = (position) =>{
    this.setState({
      position:{start: this.state.position.start+position, end: this.state.position.end+position }
    })
  }

  renderTextArea = (value) => {
    const { enableTrigger } = this.props;
    return (
      <FieldContainer isCommentOrReply socialPage borderBottom={"1"} width  position bottom widthValue={this.props.feed &&  this.props.feed.post_images.length>0 } radius={this.props.feed &&  this.props.feed.post_images.length>0} positionValue="relative">
        <CommentContent heightValue height={this.state.showGivenShoutout ? 1 : 0} h1={this.state.imgSrc.length} style={{padding:"0px 15px", margintop:"0px !important"}}>
          <InputFieldContainer comment padding={'15px'} height={'100%'} width isCommentOrReply style={{width:"100%", border:"none", height:"auto", padding:"0px", marginRight:"0px"}}>
            <SocialFeedTextarea
              userCompany={this.props.userCompany}
              addInspiration={this.onChangeValue}
              addInspirationV2={this.addInspirationV2}
              updateTextV2={this.updateTextV2}
              inspirationQuote={this.state.commentText}
              updateText={this.updateText}
              users={this.state.users}
              usersV2={this.state.usersV2}
              userIdArray={this.state.userIdArray}
              placeholder={this.props.t("Write a comment")}
              showGivenShoutout={this.state.showGivenShoutout}
              updateShoutoutArray={this.updateShoutoutArray}
              emptyShoutoutArray={this.emptyShoutoutArray}
              onRemoveShoutout={this.onRemoveShoutout}
              isCommentOrReply={"isCommentOrReply"}
              shoutoutText={this.state.shoutoutText}
              shoutoutImage={this.state.shoutoutImage}
              taggedUser={this.state.taggedUser}
              onRemoveUser={this.onRemoveUser}
              enableTrigger={enableTrigger}
              ShowEmojiContainer={this.state.ShowEmojiContainer}
              myref={this.myref}
              className={'CustomTextAreaField'}
              selectedImage={this.state.imgSrc[0]}
              shoutOutData={null}
              removeImage={this.removeImage}
              updatePosition={this.updatePosition}
              style={{width:"100%"}}
              inputRefData={this.inputRefData}
              activeCommentTextArea={this.state.activeCommentTextArea}
              isSingleFeed={true}
            />

            <IconContainer cameraIcon style={{ width:"20%",justifyContent: "flex-end", position:"initial", marginTop:"0px", display: "flex", margin: "auto", marginBottom: "5px" }}>
              <div style={{width:"46px", height:"46px", border:"1px solid #005C874D", display:"flex", borderRadius:"50%"}}>
                <img style={{ marginTop: '0px', cursor: 'pointer', width: '27px', height: '27px', margin:"auto" }} onClick={this.showEmojiPicker} src={ImageUrl+"/social-feed/Emoji.png"}></img>
              </div>
              <SendButton disabled={this.state.commentText.length > 0 || this.state.userIdArray.length > 0||this.state.imgSrc.length>0?0:1}>
                <img src={ImageUrl + "/social-feed/sendButton.png"} onClick={this.state.commentText.length > 0 || this.state.userIdArray.length > 0||this.state.imgSrc.length>0? () => this.addComment():null}/>
              </SendButton>  
            </IconContainer>
          </InputFieldContainer>
        </CommentContent>
        {this.state.displayEmojiPicker ? <SocialFeedsEmojiPicker comment selectEmoji={this.addInspirationV2} hidePicker={this.showEmojiPicker}></SocialFeedsEmojiPicker> : null}
        <RecognitionSection>
          <div>
            <div onClick={() => this.displayEmojiContainer()}>
              <img src="/public/images/social/peer_recognition.svg" />
            </div>
            <div>
              <img src="/public/images/CommentReplyImages/camera.png" />
              <input
                id="uploadPhoto"
                type="file"
                name="image"
                accept=".jpeg, .png, .jpg*"
                multiple={false}
                onChange={(e) => this.props.onChange(e)}
                onClick={(e) => e.target.files[0] && this.props.onChange(e)}
              />
            </div>
            <div onClick={() => this.showEmojiPicker()}>
              <img src="/public/images/CommentReplyImages/Emoji_2.png" />
            </div>
          </div>
          <button onClick={this.addComment} disabled={this.togglePostButton(value)}>
            <img src="/public/images/CommentReplyImages/addPost.png" />
          </button>
        </RecognitionSection>
      </FieldContainer>
    )
  }

  renderCommentArea = () => {
    const { enableTrigger, user } = this.props;
    return (
      <CommentBoxWrap>
        <div className='profile-picture'>
          {/* <img src={ImageUrl+"/"+user.profile_image}/> */}
          {!this.containsDefaultPng(user.profile_image)?
            <img src={ImageUrl + "/" +user.profile_image}/>:
            <UserInitialsMain>{`${this.getInitials(user?.fname)}${this.getInitials(user?.lname)}`}</UserInitialsMain>}
        </div>
        <CommentTextPopup border={this.state.commentText?.length>499} width={this.state.commentText.length > 0 || this.state.userIdArray.length > 0?"100% !important":"80%"}>
          <SocialFeedTextarea
            userCompany={this.props.userCompany}
            addInspiration={this.onChangeValue}
            addInspirationV2={this.addInspirationV2}
            updateTextV2={this.updateTextV2}
            inspirationQuote={this.state.commentText}
            updateText={this.updateText}
            users={this.state.users}
            usersV2={this.state.usersV2}
            userIdArray={this.state.userIdArray}
            placeholder={this.props.t("Write a comment...")}
            showGivenShoutout={this.state.showGivenShoutout}
            updateShoutoutArray={this.updateShoutoutArray}
            emptyShoutoutArray={this.emptyShoutoutArray}
            onRemoveShoutout={this.onRemoveShoutout}
            isCommentOrReply={"isCommentOrReply"}
            shoutoutText={this.state.shoutoutText}
            shoutoutImage={this.state.shoutoutImage}
            taggedUser={this.state.taggedUser}
            onRemoveUser={this.onRemoveUser}
            enableTrigger={enableTrigger}
            ShowEmojiContainer={this.state.ShowEmojiContainer}
            myref={this.myref}
            className={'CustomTextAreaField'}
            selectedImage={this.state.imgSrc[0]}
            shoutOutData={null}
            removeImage={this.removeImage}
            updatePosition={this.updatePosition}
            style={{width:"100%"}}
            inputRefData={this.inputRefData}
            activeCommentTextArea={this.state.activeCommentTextArea}
            isSingleFeed={true}
          />
          {this.state.commentText.length > 0 || this.state.userIdArray.length > 0?
            <IconContainer width="100% !important" cameraIcon style={{ width:"100% !important",justifyContent: "space-between", position:"initial", display: "flex", margin: "12px auto auto auto" }}>
              <div style={{width:"24px", height:"24px", display:"flex", borderRadius:"50%"}}>
                <img style={{ marginTop: '0px', cursor: 'pointer', margin:"auto" }} onClick={this.showEmojiPicker} src={ImageUrl+"/social-feed/emojiicon.svg"}></img>
              </div>
              <div className='sendbutton' onClick={()=>this.addComment()}>
                Comment
              </div>
            </IconContainer>:
            <IconContainer cameraIcon style={{ width:"20% !important",justifyContent: "flex-end", position:"initial", marginTop:"0px", display: "flex", margin: "auto 0 auto auto" }}>
              <div style={{width:"24px", height:"24px", display:"flex", borderRadius:"50%"}}>
                <img style={{ marginTop: '0px', cursor: 'pointer', margin:"auto" }} onClick={this.showEmojiPicker} src={ImageUrl+"/social-feed/emojiicon.svg"}></img>
              </div>
            </IconContainer>}
        </CommentTextPopup>
      </CommentBoxWrap>
    )
  }

  navigateUserProfile = (uid) => {
    const { history, userId, fetchUserPosts } = this.props;
    fetchUserPosts(uid, history);
    if (userId === uid) {
      history.push('/profile');
    } else {
      history.push(`/profile/${uid}`);
    }
  };

  containsDefaultPng = (str) => {
    const defaultPngFound = str?.includes("default.png");
    return defaultPngFound;
  }

  getInitials = (inputString) =>{
    const words = inputString.split(' ');
    const initials = words.map((word) => word[0].toUpperCase());
    return initials.join('');
  }

  imgSrc = (isOwn) => {
    const { userId, feed, profileImage } = this.props;
    const { notBase64 } = this.state;
    if (isOwn) {
      if (!notBase64) {
        return (!this.containsDefaultPng(profileImage)?<img src={`${ImageUrl}/${profileImage}`} />:<UserInitialsMain>{`${this.getInitials(feed?.fname)}${this.getInitials(feed?.lname)}`}</UserInitialsMain>)
      }
      else {
        return (!this.containsDefaultPng(profileImage)?<Image image={profileImage} />:<UserInitialsMain>{`${this.getInitials(feed?.fname)}${this.getInitials(feed?.lname)}`}</UserInitialsMain>)
      }
    } else if (userId == feed.uid) {
      if (!notBase64) {
        return (!this.containsDefaultPng(profileImage)?<img src={`${ImageUrl}/${profileImage}`} />:<UserInitialsMain>{`${this.getInitials(feed?.fname)}${this.getInitials(feed?.lname)}`}</UserInitialsMain>)
      }
      else {
        return (!this.containsDefaultPng(profileImage)?<Image image={profileImage} />:<UserInitialsMain>{`${this.getInitials(feed?.fname)}${this.getInitials(feed?.lname)}`}</UserInitialsMain>)
      }
    }
    else {
      return (!this.containsDefaultPng(feed?.profile_image)?<img src={`${ImageUrl}/${feed?.profile_image}`} />:<UserInitialsMain>{`${this.getInitials(feed?.fname)}${this.getInitials(feed?.lname)}`}</UserInitialsMain>)
    }
  };

  getTaggedUserId = (e) => {
    if (e.target.id) {
      this.navigateUserProfile(e.target.id);
    }
  };

  displayRemovePopUp = () => {
    const { socialCallBack, feed } = this.props;
    this.setState((prev) => ({ showRemoveBuddyPopUp: !prev.showRemoveBuddyPopUp }));
    if (feed.friend === 1) {
      this.setState({
        currentButton: 'Remove Buddy'
      })
    } else if (feed.friend === 0) {
      this.setState({
        currentButton: 'Add Buddy'
      })
    } else if (feed.friend === 2) {
      this.setState({
        currentButton: 'Pending'
      })
    } else {
      this.setState({
        currentButton: ''
      })
    }
    socialCallBack();
  }

  showRecognition = () => {
    this.setState((prev) => ({
      showRecognitionModal: !prev.showRecognitionModal
    }));
  }

  onShoutoutSelect = (text, id, image) => {
    const { userIdArray } = this.state;
    this.setState((prev) => ({
      showRecognitionModal: !prev.showRecognitionModal,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: id ? id : null,
      exemplifyID: id ? id : null
    }));
    this.updateShoutoutArray(userIdArray, text, image, id);
  }

  renderRecognitionModal = () => {
    const { exemplifiesValues } = this.props;
    return (
      <div>
        <StyledModal
          show={this.state.showRecognitionModal}
          onHide={this.showRecognition}
        >
          <StyledBody style={{ padding: '0px' }}>
            <MainContent>
              {
                exemplifiesValues ? exemplifiesValues.map((recognition) => (
                  <PeerButton
                    value={recognition.core_value}
                    onClick={() => this.onShoutoutSelect(recognition.core_value, recognition.id, recognition.image)}
                    key={recognition.id}
                  >
                    <img src={`${ImageUrl}/${recognition.image}`} height={recognition.image.includes('passionate.png') ? '30px' : '20px'} />
                    <div>{recognition.core_value}</div>
                  </PeerButton>
                ))
                  : null
              }
            </MainContent>
          </StyledBody>
        </StyledModal>
      </div>
    )
  }

  renderViewCommentModal = () => {
    this.setState((prev) => ({
      showViewCommentModal: !prev.showViewCommentModal
    }))
    this.props.handleScrollTrigger();
  }

  showCommentBox = () => {
    this.setState((prev) => ({
      showCommentArea: !prev.showCommentArea
    }))
  }

  addBuddyApiCall= (id, feedId) =>{
    this.props.sendFriendRequest(id);
    this.props.getSuggestionsList();
    this.props.socialCallBack();
    setTimeout(() => {
      this.props.getInspirationPost(feedId);
    }, 3000);
  }

  changeEditDeletePopupCall = (id, data)=>{
    this.props.changeEditDeletePopup(id, data);
    this.props.history.push({pathname:`/social`,state:null});
    // this.props.closeCommentPoup();
  }

  showMoreText=()=>{
    this.setState({
      showmore:true
    })
  }

  render() {
    const { commentText, showAddBuddy, showRemoveBuddyPopUp, showmore, feedId } = this.state;
    const { feed, userName, userId, buddyDetails, history, socialCallBack, description, t, onClosePopup } = this.props; 
    let feedMultiImage = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      draggable: true,
      adaptiveHeight: true,
      nextArrow: <div className={"left-arrow slick-next"} />,
      prevArrow: <div className={"left-arrow slick-prev"} />,
      responsive: [
        {
          breakpoint: 1252,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
      ],
    };
    const singleFeedData=_.isEmpty(feed)?this.props.inspirationPost:feed;
    let lang = localStorage.getItem("lang");
    return (
      <div id={feed.id} key={feed.id + "key"} style={{background:"white", height:"600px", borderRadius:"0 6px 6px 0"}}>
        <HeaderContainer  onModal>
          <ImageWrapper display={1} borderRadius={'50%'}>
            {this.imgSrc(false)}
          </ImageWrapper>
          <UserInformation isOwnPost={userId == feed.uid} >
            <div className='name'>{(feed.fname && feed.lname) ? (feed.fname + ' ' + feed.lname) : userName}</div>
            <div className='time'>
              {feed.location}
            </div>
            <div className='time'>    { lang != 'fr' ? convertMonthFromDate(moment(convertDateInTimezone(feed.created_at)._d).format('MMM DD, YYYY'), this.props) : convertMonthFromDate(moment(convertDateInTimezone(feed.created_at)._d).format('DD MMM YYYY'), this.props) } |&nbsp; <img src={ImageUrl+"/social-feed/time.svg"}/></div>
          </UserInformation>
          {
            <div className='buttondiv'>
              {(userId === feed.uid) && 
              <>
                <DownAngleV2 margin feed>
                  <DropdownButton id="editDelete" title={<img src={ImageUrl+"/social-feed/menu.svg"} style={{width:"24px", height:"auto", background:"none !important"}}/>}>
                    <Dropdown.Item onClick={() => this.changeEditDeletePopupCall('delete', feed)} >
                      {t("Delete Post")}
                    </Dropdown.Item>
                  </DropdownButton>
                </DownAngleV2>
                <div className='closeicon' onClick={()=>onClosePopup()}>
                  {closeIcon()}
                </div>
              </>}
            </div>
          }
          {(feed?.friend === 2||feed?.friend === 0 ||feed?.friend === 1) &&
            <div className='buttonWrapper'>
              <div className='closeicon' onClick={()=>onClosePopup()}>
                {closeIcon()}
              </div>
              {feed.friend === 0 ?
                <AddButton onClick={() => this.addBuddyApiCall(feed.uid, feed.id)} >
                  <div className='icons'>
                    <img src={ImageUrl+"/social-feed/add-buddy.svg"}/>
                  </div>
                  <div className='name'>
                    {"Connect"}
                  </div>
                </AddButton>:
                feed.friend === 2 ?
                  <AddButton onClick={() => this.showAddBuddyPopUp(feed.uid, feed.id)}>
                    <div className='icons'>
                      <img src={ImageUrl+"/social-feed/pending.svg"}/>
                    </div>
                    <div className='name'>
                      {"Pending"}
                    </div>
                  </AddButton>:null}
            </div>
          } 
        </HeaderContainer>
        <FeedInformation style={{height:"502px"}}>
          <PostCommentsWrapper >
            <div style={{width:"100%", display:"block"}}>
              {description != null && description !== '' && <Description width="true" contents paddingmain="0px" text={showmore?'':"60px"}>
                <div className='truncate'>{createSafeHTML(description ? convertUnicode(description).split('\\n').join('<br />') : '')}</div>
                {!showmore&& description?.length>144&&<div className='more-text' onClick={()=>this.showMoreText()}>... more</div>}
              </Description>}
            </div>
            {(singleFeedData?.post_like_users?.length>0)?<LikeCommentCount commentpopup>
              <div>
                {
                  singleFeedData?.post_like_users.length > 0 ?
                    singleFeedData?.post_like_users.length === 1 ? (
                      <span id={`like-${singleFeedData.id}-text`} className='simpletext'>{"Liked by"}&nbsp;{singleFeedData.post_like_users[0].fullname}</span>
                    ) : (
                      singleFeedData.post_like_users && singleFeedData.post_like_users.length > 1 ? (
                        <>
                          <span className='simpletext'>{"Liked by"}&nbsp;{`${singleFeedData.post_like_users[0].fullname}`} &nbsp;{this.props.t("and")}&nbsp;</span> <span className='boldText' onClick={this.handleLikePopUp}>{`${singleFeedData.post_like_users.length - 1} `+this.props.t(" others")}</span>
                        </>
                      ) : (
                        <div />
                      )) : null
                }
              </div>
            </LikeCommentCount>:null}
            {singleFeedData?.post_like_users.length>0?null:<div style={{background:"rgba(0,92,135,0.10)", display:"flex", width:"100%", height:"1px"}}></div>}
            <LikeCommentButtonSection commentpopup>
              <div className='commentSection'>
                <LikeCommentButton onClick={this.likeUnlikePost}>
                  <div className='icon'>
                    {singleFeedData?.post_like_users.length ?<img src={ImageUrl+"/social-feed/like_filed.svg"} />:<img src={ImageUrl+"/social-feed/like.svg"} />}
                  </div>
                  <div className='text'>Like</div>
                </LikeCommentButton>
              </div>
              <div className='likeSection'>
                <LikeCommentButton >
                  <div className='icon' onClick={()=>this.handleActivation()}>
                    <img src={ImageUrl+"/social-feed/comment.svg"} />
                  </div>
                  <div className='text'>Comment</div>
                </LikeCommentButton>
              </div>
            </LikeCommentButtonSection> 
            {feed && feed.post_images && feed.post_images.length>1&& this.state.hideCommentText?
              <SliderContainer
                display="flex"
                content="center"
                shadow="0px 8px 25px 0px #0D427040"
              >
                <Slider {...feedMultiImage}>
                  {feed && feed.post_images && feed.post_images.map((data,index)=>(
                    <WrapImage  key={index}>
                      <img src={`${ImageUrl}/${data.image}`} alt={data.body || 'placeholder'} />
                    </WrapImage>))}
                </Slider>
              </SliderContainer>:
              feed.image && this.state.hideCommentText ? 
                <WrapImage >
                  <LazyImage src={`${ImageUrl}/${feed.image}`} alt={feed.body || 'placeholder'} />
                </WrapImage>: 
                null}
            {this.renderCommentArea(commentText)}
            {this.state.commentText?.length>499&&<MaxText>You have exceeded the maximum character limit</MaxText>}
            {this.state.displayEmojiPicker ? <SocialFeedsEmojiPicker comment selectEmoji={this.addInspirationV2} hidePicker={this.showEmojiPicker}></SocialFeedsEmojiPicker> : null}
            {
              _.isEmpty(this.props.comments) ? null :
                <CommentsV3
                  comments={this.props.comments}
                  postReply={this.props.postReply}
                  replySuccess={this.props.replySuccess}
                  id={singleFeedData.id}
                  history={this.props.history}
                  enableTrigger={this.props.enableTrigger}
                  onChange={this.props.onChange}
                  feed={singleFeedData}
                  fromSocialFeed={false}
                  handlePhotoPostModal={this.props.handlePhotoPostModal}
                  forCommentPopup={true}
                  isCommentModal={this.props.isCommentModal}
                />
            }
          </PostCommentsWrapper>
        </FeedInformation>
        {/* {this.state.showTextArea && this.renderTextArea(commentText)} */}
        {this.state.showRecognitionModal && this.renderRecognitionModal()}
        {showRemoveBuddyPopUp&&<RemoveFriendPopup
          showModal={showRemoveBuddyPopUp}
          onClose={this.displayRemovePopUp}
          buddyDetails={feed}
          history={history}
          showRemoveBuddyPopUp={showRemoveBuddyPopUp}
          hideCancel={1}
          socialCallBack={socialCallBack}
        />}
        {showAddBuddy&&<DeletePostPopUp
          showModal={showAddBuddy}
          onClose={this.showAddBuddyPopUp2}
          buddyDetails={buddyDetails}
          showRemoveBuddyPopUp={showRemoveBuddyPopUp}
          hideCancel={0}
          socialCallBack={socialCallBack}
          pendingStatus={feed.friend === 2}
          showpending= {feed.friend === 2}
          showAddBuddy={feed.friend===0}
          feedID={feedId}
        />}
      </div>
    )
  }
}

SingleFeed.propTypes = {
  feed: PropTypes.object.isRequired,
  disableLikeButton: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
  postComment: PropTypes.func.isRequired,
  likeOrUnlikePost: PropTypes.func.isRequired,
  successLikeOrUnlike: PropTypes.string,
  getCommentsForPost: PropTypes.func.isRequired,
  getCommentsForPostModal: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserName: PropTypes.func.isRequired,
  userName: PropTypes.string,
  updationList: PropTypes.string,
  changeEditDeletePopup: PropTypes.func.isRequired,
  openEditAndDelete: PropTypes.func.isRequired,
  editAndDelete: PropTypes.bool.isRequired,
  userId: PropTypes.number,
  fetchUserId: PropTypes.func,
  history: PropTypes.object,
  description: PropTypes.string,
  commentCount: PropTypes.number,
  fetchUserPosts: PropTypes.func.isRequired,
  taggedUsers: PropTypes.string,
  userCompany: PropTypes.object.isRequired,
  enableTrigger: PropTypes.bool,
  buddyDetails: PropTypes.array,
  sendFriendRequest: PropTypes.func,
  onChange: PropTypes.func,
  handleModal: PropTypes.func.isRequired,
  handleFeed: PropTypes.func,
  unFriendUser: PropTypes.func,
  getPostLikeDetails: PropTypes.func,
  feedNumber: PropTypes.number,
  postLikeDetails: PropTypes.array,
  data: PropTypes.array.isRequired,
  addBuddy: PropTypes.func,
  exemplifiesValues: PropTypes.array,
  showRecognition: PropTypes.func,
  feedNo: PropTypes.number,
  value: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.string,
  updateFeedsLike: PropTypes.func,
  editPost: PropTypes.func,
  storedFeeds: PropTypes.array,
  socialCallBack: PropTypes.func,
  handlePhotoPostModal: PropTypes.func,
  comments: PropTypes.array,
  postReply: PropTypes.func,
  replySuccess: PropTypes.string,
  hideCommentText: PropTypes.bool,
  showViewCommentModal: PropTypes.bool,
  renderViewCommentModal: PropTypes.func,
  specialFeed: PropTypes.object,
  renderViewCommentAndGetComment: PropTypes.func,
  handleScrollTrigger: PropTypes.func,
  t: PropTypes.func,
  hideValuesInCommentsModel: PropTypes.bool,
  commentId: PropTypes.number,
  fromSocialFeed: PropTypes.bool,
  inspirationPost: PropTypes.array,
  getSuggestionsList: PropTypes.func,
  getInspirationPost: PropTypes.func,
  closeCommentPoup: PropTypes.func,
  isCommentModal:PropTypes.bool,
  onClosePopup: PropTypes.func,
  user: PropTypes.object,
  updatedData: PropTypes.object
};

const mapStateToProps = (state) => ({
  success: state.social.success,
  userId: state.profileData.userId,
  userCompany: state.profileData.userCompany,
  postLikeDetails: state.social.postLikeDetails,
  buddyDetails: state.social.buddyDetails,
  userPosts: state.social.userPosts,
  inspirationPost: state.social.inspirationPost
});

const mapDispatchToProps = (dispatch) => ({
  postComment: (commentData, updationList) => dispatch(addCommentOnPost(commentData, updationList)),
  disableLikeButton: () => dispatch(disableButton()),
  likeOrUnlikePost: (data, updationList) => dispatch(likeUnlikePost(data, updationList)),
  fetchUserName: () => dispatch(getUserName()),
  fetchUserId: () => dispatch(getUserId()),
  fetchUserPosts: (id, history) => dispatch(getUserPosts(id, history)),
  sendFriendRequest: (buddyId, bool) => dispatch(sendFriendRequest(buddyId, bool)),
  unFriendUser: (data, bool) => dispatch(unFriendUser(data, bool)),
  getPostLikeDetails: (data) => dispatch(getPostLikeDetails(data)),
  addBuddy: (data) => dispatch(addBuddy(data)),
  getSuggestionsList: () => dispatch(getSuggestionsList()),
  getInspirationPost: (id) => dispatch(getInspirationPost(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SingleFeed));